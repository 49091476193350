<!--
	This is the illustration sign up page, it uses the dashboard layout in:
	"./layouts/Default.vue" .
 -->

<template>

  <!-- Default Layout -->
  <a-layout class="layout-default" id="layout-default" :class="[layoutClass]">

<!--    &lt;!&ndash; Layout Header ( Navbar ) &ndash;&gt;-->

<!--    <a-layout-header style="left: 20px;right: auto">-->
<!--      <div class="header-col header-brand">-->
<!--        <h6>CuteMeet</h6>-->
<!--      </div>-->
<!--    </a-layout-header>-->
<!--    &lt;!&ndash; / Layout Header ( Navbar ) &ndash;&gt;-->


    <!-- Page Content -->
    <a-layout-content>
      <div class="sign-in">

        <div class="logo-container">
          <div class="brand"><img style="height: 35px !important;" src="images/logo-ct-black.png" alt=""> <span style="background: linear-gradient(to right, #30cfd0, #330867);
        -webkit-background-clip: text;
        color: transparent;font-size: 18px;font-weight: 600">CuteMeet</span></div>
        </div>
        <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="row-main" style="min-height: 500px">

          <!-- Sign Up Form Column -->
          <a-col :span="24" :md="24" :lg="{ span: 10, offset: 1 }" :xl="{ span: 6, offset: 2 }" class="col-form mr-auto">
            <div class="card-container">
              <div class="box">
                <div class="back-div">
                  <h4 class="mb-15">
                    <span class="login-animation"><i class="loader-1"></i></span>
                    <span class="login-title">登录</span>
                  </h4>
                  <p class="text-muted" style="font-size: 12px">Welcome to CuteMeet.</p>
                  <!-- Sign Up Form -->
                  <a-form
                      id="components-form-demo-normal-login"
                      style="margin-top: 20px"
                      :form="form"
                      class="login-form"
                      @submit="handleSubmit"
                      :hideRequiredMark="true"
                  >
                    <a-form-item class="mb-10">
                      <a-input v-model="formData.username" placeholder="用户名/手机号/邮箱">
                        <a-icon slot="prefix" type="user" />
                        <a-tooltip slot="suffix" title="请输入用户名">
                          <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
                        </a-tooltip>
                      </a-input>
                    </a-form-item>
                    <a-form-item class="mb-10">
                      <a-input-password v-model="formData.password" placeholder="密码">
                        <a-icon slot="prefix" type="lock" />
                      </a-input-password>
                    </a-form-item>
                    <a-form-item class="mb-10">
                      <a-checkbox
                          style="color: rgba(0,0,0,.45);font-weight: 500;font-family: iconfont,Calibri,Segoe,“Segoe UI”,Optima,Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Arial,sans-serif!important;letter-spacing: 1px;font-size: 10px" v-model="formData.remember">
                        已阅读并同意 <a href="javascript:void(0)" class="font-bold text-dark" style="color: rgb(15, 142, 233) !important;">《用户协议》</a>
                      </a-checkbox>
                    </a-form-item>
                    <a-form-item>
                      <a-button type="default" block html-type="submit" class="login-form-button" @click="handleSubmit">
                        登录
                      </a-button>
                    </a-form-item>
                  </a-form>
                  <!-- / Sign Up Form -->

                  <p class="font-semibold text-muted text-center" style="text-align: right;width: 90%;margin-top: 10px"><router-link to="/sign-in" class="font-bold text-dark" style="color: rgba(0, 0, 0, .3) !important;font-weight: normal;text-decoration: underline;font-size: 12px">忘记密码</router-link></p>
                </div>
              </div>
            </div>
          </a-col>
          <!-- / Sign Up Form Column -->

          <!-- Sign Up Image Column -->
          <a-col :span="24" :md="24" :lg="12" :xl="14" class="loader-container">
            <div class="loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <span>CuteMeet</span>
            </div>
            <div>
<!--              <div class="img">-->
<!--                <img src="images/info-rocket-ill.png" alt="rocket">-->
<!--              </div>-->
<!--              <h4 class="text-white">CuteMeet</h4>-->
<!--              <p class="text-white">Just as it takes a company to sustain a product, it takes a community to sustain a protocol.</p>-->
            </div>
          </a-col>
          <!-- / Sign Up Image Column -->

        </a-row>
        <div style="position: fixed;bottom: 20px;right:25px;text-align: right;width: 100%;color: #72848c">网站备案号：<a href="https://beian.miit.gov.cn/" target="_blank" style="color: #72848c">皖ICP备16025013号-1</a></div>
      </div>
    </a-layout-content>
    <!-- / Page Content -->

  </a-layout>
</template>

<script>

import { setToken } from "@/utils/auth"
import {getTenantCode} from "../utils/common";

export default ({
  data() {
    return {
      // Sign up form object.
      form: this.$form.createForm(this, { name: 'login' }),
      formData: {
        username: '',
        password: '',
        remember: true,
        tenantCode: ''
      }
    }
  },
  computed: {
    // Sets layout's element's class based on route's meta data.
    layoutClass() {
      return this.$route.meta.layoutClass ;
    }
  },
  methods: {
    // Handles input validation after submission
    handleSubmit(e) {
      e.preventDefault();
      if (!this.formData.username) {
        this.$message.warning('用户名不可为空')
        return
      }
      if (!this.formData.password) {
        this.$message.warning('密码不可为空')
        return
      }
      if (!this.formData.remember) {
        this.$message.warning('请先阅读并同意《用户协议》')
        return
      }
      const loginForm = {
        username: this.formData.username.substring(this.formData.username.indexOf("/") + 1, this.formData.username.length),
        password: this.formData.password,
        tenantCode: getTenantCode(this.formData.username),
        lang: localStorage.getItem('lang')
      }
      this.$store.dispatch('LOGIN', loginForm).then(res => {
        if (res.errorCode === '0') {
          setToken(res.data)
          this.$router.push("/dashboards/index")
        } else {
          this.$message.error(res.message)
        }
        console.log(res)
      })
    }
  },
})

</script>

<style>
.card-container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  position: relative;
  z-index: 8;
}
.card-container .box {
  position: relative;
  width: 100%;
  height: 400px;
  background: rgba(0, 0, 0, .75);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.card-container .box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 500px;
  background-image: conic-gradient(transparent, transparent, transparent, #00ccff);
  animation: animate 4s linear infinite;
}
.card-container .box::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 500px;
  background-image: conic-gradient(transparent, transparent, transparent, #de44d4);
  animation: animate 4s linear infinite;
  animation-delay: -2s;
}
.card-container .box .back-div {
  position: absolute;
  inset: 4px;
  border-radius: 16px;
  background: #ffffff;
  z-index: 1;
  padding: 10px 10px;
  padding-top: 20px;
}
.card-container .box input::placeholder {
  font-weight: 400;
  font-size: 12px;
}
@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.logo-container {
  position: absolute;
}
.login-animation{
  display: inline-block;
  margin: 0;
  border: 0;
}
.login-animation .loader-1 {
  width: 20px;
  height: 20px;
  border: 3px solid transparent;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  animation: rotation 1s linear infinite;
}
.login-animation .loader-1:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #999;
  border-top-color: #999;
}
.login-title {
  display: inline-block;
  padding: 0 10px;
  background: transparent;
  font-size: 22px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}
.loader-container div,
.loader-container span {
  margin: 0;
  padding: 0;
}
.loader-container {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 0;
  text-align: center;
  align-items: center;
  background: linear-gradient(15deg, #13547a, #80d0c7);
}
.loader-container .loader {
  position: relative;
  width: 200px;
  height: 200px;
}
.loader-container .loader div {
  position: absolute;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 2s ease infinite;
}
.loader-container .loader span {
  position: absolute;
  border: 5px solid transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  left: 70px;
  top: 70px;
  font-weight: bold;
  color: #fff;
}
.loader-container .loader div:nth-child(1) {
  width: 90px;
  height: 90px;
  left: 50px;
  top: 50px;
}
.loader-container .loader div:nth-child(2) {
  width: 110px;
  height: 110px;
  left: 40px;
  top: 40px;
  animation-delay: .1s;
}
.loader-container .loader div:nth-child(3) {
  width: 130px;
  height: 130px;
  left: 30px;
  top: 30px;
  animation-delay: .2s;
}
.loader-container .loader div:nth-child(4) {
  width: 150px;
  height: 150px;
  left: 20px;
  top: 20px;
  animation-delay: .3s;
}

@keyframes spin {
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>